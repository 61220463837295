<template>
<div>
    <v-file-input dense outlined v-model="file" @change="add" label="upload file"></v-file-input>

</div>
</template>
<script>
import { Storage } from 'aws-amplify';
export default {
    name: 'ImageUpload',
    props: {
        image_info: Object
    },
    data(){
        return {
            file: {},

        }
    },
    methods: {
        createGenerativeNumber() {
            return Math.floor(100000000 + Math.random() * 900000000)
        },
        async add() {

            const result = await Storage.put(this.file.name, this.file);
            console.log(result)
            this.image_info.url = 'https://reportsv01479dfcd2763446cd8a3c46396e311427120545-reports.s3.eu-west-1.amazonaws.com/public/' + result.key
        },
    }
}
</script>