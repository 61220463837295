<template>
<div>
    <v-file-input dense outlined multiple v-model="files" @change="add" label="upload files"></v-file-input>

</div>
</template>
<script>
import { Storage } from 'aws-amplify';
export default {
    name: 'ImageUpload',
    props: {
        image_info: Object,
        images_urls: Array
    },
    data(){
        return {
            files: [],

        }
    },
    methods: {
        createGenerativeNumber() {
            return Math.floor(100000 + Math.random() * 900000000)
        },
        async add() {

            for (var i = 0; i< this.files.length; i ++ ){
                const result = await Storage.put(this.createGenerativeNumber().toString() + this.files[i].name, this.files[i]);
                console.log(result)
            this.images_urls.push( 'https://reportsv01479dfcd2763446cd8a3c46396e311427120545-reports.s3.eu-west-1.amazonaws.com/public/' + result.key)
      
            }
          },
    }
}
</script>