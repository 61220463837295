import axios from 'axios'
const application_client_id = '1c4d136a-76a4-427c-8e3b-c5170cfed845';
const application_client_secret = '4b48daba-ef0c-4561-869b-c4b66a7cdb03';
import { Storage } from 'aws-amplify';
function createGenerativeNumber() {
  return Math.floor(100000000 + Math.random() * 900000000)
}

const actions = {

  async getFundAllocation ({  commit }, form) {
    return   axios
                        .post('https://apiss.ardkon.com/GetFundAllocation?hello=ss', form)
                        .then(response => {
    
                           commit('SET_FUND_ALLOCATION', response.data.data.action_funds_allocation)
    
    
    
    
                        }).catch(error => {
                              console.log(error)
                          });
        },
  async  getActions ({  commit }) {

    let form = {action_status: 'live'}
              return  axios
                            .post('https://apiss.ardkon.com/GetActions?hello=ss', form)
                            .then(response => {

                               commit('SET_ACTIONS', response.data.data.actions)


    }).catch(error => {
          console.log(error)
      });
   },


   async submit({state}, form){

    var data = {}
    data.step_1 = state.step_1
    data.step_2 = state.step_2
    data.step_3 = state.step_3
    data.step_4 = state.step_4
    data.step_5 = state.step_5
    data.step_6 = state.step_6
    data.step_7 = state.step_7
    data.step_8 = state.step_8
    data.step_9 = state.step_9
    data.stages = state.stages
     await Storage.put(form.name+ createGenerativeNumber() + "-report.json", JSON.stringify(data));
   },
  
  saveData({commit}, form){

            commit('PRE_SAVE',form)

  },
save({commit}, form){
  commit('SAVE', form)
},
    getProfile({commit, rootState}, form) {

        form.access_token = rootState.accounts_persist.user_information.token_info.access_token
        form.application_client_id = application_client_id;
        form.application_client_secret = application_client_secret;

        axios.post('https://apiss.ardkon.com/GetProfile?mvp2=profiles', form).then((response) => {
            commit('GET_PROFILE', response.data.data.app_info.profile_info)
           
        }).catch((error) => {
            console.log(error);
        });


    },
    initializeData({commit}){
        commit('INITIALIZE_DATA')
    }
  
}

const reports = {
    namespaced: true,
    state: () => ({
        step_1: [
            {
              title: 'name of your initiative',
              field_type: 'text-field',
              input_type: 'text',
      
              label: 'initiative name',
              items: [],
              value: '',
              input_id: 'initiative_name'
      
            }, {
              title: 'contact person',
              field_type: 'text-field',
              label: 'person name',
              items: [],
              input_type: 'text',
      
              value: '',
              input_id: 'person_contact'
      
            }, {
              title: 'contact email',
              field_type: 'text-field',
              label: 'email',
              input_type: 'email',
      
              items: [],
              value: '',
              input_id: 'contact_email'
      
            }, {
              title: 'name of the action',
              field_type: 'text-field',
              input_type: 'text',
      
              label: 'action_name',
              items: [],
              value: '',
              input_id: 'action_name'
      
            }, {
              title: 'link to your action page',
              field_type: 'text-field',
              label: 'link',
              input_type: 'text',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, {
              title: 'When did you launch your action?',
              field_type: 'text-field',
              label: 'date',
              input_type: 'date',
              items: [],
              value: '',
              input_id: 'launch_date'
      
            }, {
              title: 'Is this the first action that you launched on ardkon platform?',
              field_type: 'select-input',
              label: 'action',
              input_type: 'text',
              items: ['yes', 'no, we already did this one:'],
              value: '',
              input_value_condition: 'no, we already did this one:',
              value_2: '',
              label_2: 'action page link',
              input_id: 'first_action'
      
            }, {
              title: 'We are filling out this survey because: ',
              field_type: 'select',
              label: 'reason',
              input_type: 'text',
              items: ['we reached a funding goal', 'we benefitted from a successful support act (event etc.)', 'the three-month follow-up time has passed after the release of funds'],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: 'action page link',
              input_id: 'reason'
      
            },
          ],
        step_2: [

            {
              title: 'For which fund allocation did you decide, according to the plan we put together on your action page? ',
              field_type: 'checkbox',
              label: 'method',
              input_type: 'text',
              items: [],
              value: [],
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'fund_method'
      
            }, {
              title: 'How was it placed:',
              field_type: 'select',
              label: 'method',
              input_type: 'text',
              items: ['in percentages', 'in dollar amount'],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'fund_method'
      
            }, {
              title: 'Did you follow the funding plan (allocation of percentages etc.)',
              field_type: 'select-input',
              label: 'method',
              input_type: 'text',
              items: ['yes', 'no, because:'],
              value: '',
              input_value_condition: 'no, because:',
              value_2: '',
              label_2: 'reason',
              input_id: 'why?'
      
            }, {
              title: 'Did you reach on of the goals or the maximum goal of the campaign? ',
              field_type: 'select',
              label: 'goal',
              input_type: 'text',
              items: ['we reached the total amount ', 'we reached on of the subgoals', 'our fundraising was open end'],
              value: '',
              input_value_condition: 'no, because:',
              value_2: '',
              label_2: 'reason',
              input_id: 'why?'
      
            }, {
              title: 'How was your experience with the funding plan? Did it feel realistic? What would you do differently the next time? Where would you need additional support?',
              field_type: 'text-area',
              label: 'experience',
              input_type: 'text',
              items: [],
              value: '',
              input_value_condition: 'no, because:',
              value_2: '',
              label_2: 'reason',
              input_id: 'why?'
      
            },
          ],
    step_3: [
        {
          title: 'Date',
          field_type: 'text-field',
          input_type: 'date',
  
          label: 'Date',
          items: [],
          value: '',
          input_id: 'support_date'
  
        }, {
          title: 'Title',
          field_type: 'text-field',
          input_type: 'text',
  
          label: 'Title',
          items: [],
          value: '',
          input_id: 'support_title'
  
        }, {
          title: 'Type of event:',
          field_type: 'text-field',
          input_type: 'text',
  
          label: 'event',
          items: [],
          value: '',
          input_id: 'support_type'
  
        }, {
          title: 'Organizers:',
          field_type: 'text-area',
          input_type: 'text',
  
          label: 'organizers',
          items: [],
          value: '',
          input_id: 'support_organizers'
  
        }, {
          title: 'Event page link:',
          field_type: 'text-field',
          input_type: 'text',
  
          label: 'link',
          items: [],
          value: '',
          input_id: 'support_page_link'
  
        }, {
          title: 'Amount raised:',
          field_type: 'text-field',
          input_type: 'number',
  
          label: 'link',
          items: [],
          value: '',
          input_id: 'support_amount_raised'
  
        }, {
          title: 'Did the type of the event fit well with your philosophy?',
          field_type: 'select',
          label: 'philosophy',
          input_type: 'text',
          items: ['yes', 'no'],
          value: '',
          input_value_condition: '',
          value_2: '',
          label_2: '',
          input_id: 'philosophy_type'
  
        }, {
          title: 'Did team members or volunteers of yours attend the event?',
          field_type: 'select',
          label: 'attended',
          input_type: 'text',
          items: ['yes', 'no'],
          value: '',
          input_value_condition: '',
          value_2: '',
          label_2: '',
          input_id: 'attended'
  
        }, {
          title: 'Did you share the events invite with your community?',
          field_type: 'select',
          label: 'invites',
          input_type: 'text',
          items: ['yes', 'no'],
          value: '',
          input_value_condition: '',
          value_2: '',
          label_2: '',
          input_id: 'invites'
  
        }, {
          title: 'Do you think the act achieved the best possible reach?',
          field_type: 'select-input',
          label: 'reach',
          input_type: 'text',
          items: ['yes', 'no, this might be the reason:'],
          value: '',
          input_value_condition: 'no, this might be the reason:',
          value_2: '',
          label_2: 'reason',
          input_id: 'reach'
  
        }, {
          title: 'Would you collaborate with the same organizers again?',
          field_type: 'select-input',
          label: 'reach',
          input_type: 'text',
          items: ['yes', 'no, because:'],
          value: '',
          input_value_condition: 'no, because:',
          value_2: '',
          label_2: 'reason',
          input_id: 'collaboration'
  
  
        },
        {
          title: 'Comments, feedback & ideas to the supportive act: ',
          field_type: 'text-area',
          input_type: 'text',
  
          label: 'feedback',
          items: [],
          value: '',
          input_id: 'comments_feedback'
  
        },
      ],
        step_4: [{
            title: 'Our initiative wants to continue fundraising:',
            field_type: 'select',
            label: 'philosophy',
            input_type: 'text',
            items: ['yes', 'no'],
            value: '',
            input_value_condition: '',
            value_2: '',
            label_2: '',
            input_id: 'philosophy'
      
          }, {
            title: 'We want to continue with the following forms (multiple answers possible)',
            field_type: 'select-input',
            label: 'continuation',
            input_type: 'text',
            items: ['we want to continue with this action for an extended period', 'we are interested in another supportive act', 'we are interested in creating collaboration with other initiatives', 'we’d like to wrap this action up and create a new one for the next goals', 'we already have a specific new idea to share with you:'],
            value: '',
            input_value_condition: 'we already have a specific new idea to share with you:',
            value_2: '',
            label_2: 'specific idea',
            input_id: 'continuation'
      
          },
      
          {
            title: 'Our next goals and ideas, but also questions: ',
            field_type: 'text-area',
            input_type: 'text',
      
            label: 'goals',
            items: [],
            value: '',
            input_id: 'goals'
      
          }],
        step_5: [
            {
              title: 'With which ardkon acts would you describe your action’s impact (learn about the ardkon acts here: https://www.ardkon.com/ardkon/about-us )?',
              field_type: 'select',
              label: 'philosophy',
              input_type: 'text',
              items: ['1. we do no harm', '2. we live together', '3. we drink the same ', '4. we feed all ', '5. we travel slow', '6. we work for all ', '7. we share what we know ', '8. we pray as one ', '9. we rebel'],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'acts'
      
            }, {
              title: 'Were you able to support marginalized or vulnerable groups with this action?: ',
              field_type: 'select-input',
              label: 'philosophy',
              input_type: 'text',
              items: [' unfortunately not', 'yes, which and how in particular:'],
              value: '',
              input_value_condition: 'yes, which and how in particular:',
              value_2: '',
              label_2: 'specific idea',
              input_id: 'impact_support'
      
            },
            {
              title: 'Give us a short statement on the impact that you are aiming for with your initiative and how the current action is performing in this regard. ',
              field_type: 'text-area',
              input_type: 'text',
      
              label: 'impact statement',
              items: [],
              value: '',
              input_id: 'impact_statement'
      
            }
          ],
        step_6: [
            {
              title: 'Do you know about ardkon’s democratized smart contracts? Whenever individual donations are being done for your action, your supportive community can vote on the fund allocation that they believe is most important to create more engagement between both sides. ',
              field_type: 'select',
              label: 'smart contracts',
              input_type: 'text',
              items: ['we are actively implementing the outcomes of it in our decision making. ', 'we know about it but don’t quite know how it works. ', 'we don’t know about it but are interested to hear more!', 'we don’t think it is a good idea or applicable for our operations.'],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'smart_contracts'
      
            },
          ],
        step_7: [
            {
              title: 'Any other issues, feedback or questions that you want to share with us about your action, the platform and further steps? ',
              field_type: 'text-area',
              label: 'comments',
              input_type: 'text',
              items: [],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'comments'
      
            },],
        step_8: [
            {
              title: 'receipts, contracts with freelancers or contractors ',
              field_type: 'documents',
              images_urls: [],
              input_id: 'reciepts'
            }, {
              title: 'spreadsheet over expenses & spending (in USD)',
              field_type: 'document',
              images_urls: [],
              input_id: 'expenses'
            }, {
              title: 'pictures of purchased equipment',
              field_type: 'documents',
              images_urls: [],
              input_id: 'equipment'
            }, {
              title: 'any additional material to show how you spent the donated funds',
              field_type: 'documents',
              images_urls: [],
              input_id: 'additional_material'
            }
          ],
        step_9: [
            {
              title: 'a video with your team presenting the outcome of the funds invested ',
              field_type: 'document',
              images_urls: [],
              input_id: 'team_video'
            }, {
              title: 'testimony video showing the community impact',
              field_type: 'document',
              images_urls: [],
              input_id: 'testimony_video'
            }, {
              title: 'photos, texts & other material about your current operations and how they approved',
              field_type: 'documents',
              images_urls: [],
              input_id: 'material'
            },
            {
              title: 'did you benefit from a supportive act? thank you - material (video, post etc.) for the organizers & social media ',
              field_type: 'text-area',
              label: 'benefits',
              input_type: 'text',
              items: [],
              value: '',
              input_value_condition: '',
              value_2: '',
              label_2: '',
              input_id: 'benefits'
      
            }
          ],

          stages:{
            step_1: false,
            step_2: false,
            step_3: false,
            step_4: false,
            step_5: false,
            step_6: false,
            step_7: false,
            step_8: false,
            step_9: false
          },
          actions:[]
    }),
    mutations: {

    SET_FUND_ALLOCATION(state, data) {
      state.step_2[0].items = []
                     for(let i = 0; i < data.length; i++){
      
                          state.step_2[0].items.push(data[i].fund_title);
      
                        }
      
      
                  },
      SET_ACTIONS(state, data){
        for(let i = 0; i < data.length; i++){

                            state.actions.push(data[i]);


                          }
        },
        PRE_SAVE(state,data){
          if(data.e6 === 1){
            state.step_1  = data.info
            state.stages.step_1 = true

          }
          else if(data.e6 === 2){
            state.step_2  = data.info
            state.stages.step_2 = true

          }
          else if(data.e6 === 3){
            state.step_3  = data.info
            state.stages.step_3 = true

          }
          else if(data.e6 === 4){
            state.step_4  = data.info
            state.stages.step_4 = true

          }
          else if(data.e6 === 5){
            state.step_5  = data.info
            state.stages.step_5 = true

          }
          else if(data.e6 === 6){
            state.step_6  = data.info
            state.stages.step_6 = true

          }
          else if(data.e6 === 7){
            state.step_7  = data.info
            state.stages.step_7 = true

          }
          else if(data.e6 === 8){
            state.step_8  = data.info
            state.stages.step_8 = true

          }
          else if(data.e6 === 9){
            state.step_9  = data.info
            state.stages.step_9 = true

          }
        },
        SAVE(state,data){
        if(data.e6 === 1){
          for(var i = 0; i< data.info.length; i++){
            state.step_1.push(data.info[i])
          }
          state.stages.step_1 = true
        }
        else if(data.e6 === 2){
          for(var h = 0; i< data.info.length; i++){
            state.step_2.push(data.info[h])
          }
          state.stages.step_2 = true
        }
        else if(data.e6 === 3){
          for(var g = 0; i< data.info.length; i++){
            state.step_3.push(data.info[g])
          }
          state.stages.step_3 = true
        }
        else if(data.e6 === 4){
          for(var f = 0; i< data.info.length; i++){
            state.step_4.push(data.info[f])
          }
          state.stages.step_4 = true
        }
        else if(data.e6 === 5){
          for(var e = 0; i< data.info.length; i++){
            state.step_5.push(data.info[e])
          }
          state.stages.step_5 = true
        }
        else if(data.e6 === 6){
          for(var d = 0; i< data.info.length; i++){
            state.step_6.push(data.info[d])
          }
          state.stages.step_6 = true
        }
        else if(data.e6 === 7){
          for(var c = 0; i< data.info.length; i++){
            state.step_7.push(data.info[c])
          }
          state.stages.step_7 = true
        }
        else if(data.e6 === 8){
          for(var b = 0; i< data.info.length; i++){
            state.step_8.push(data.info[b])
          }
          state.stages.step_8 = true
        }
        else if(data.e6 === 9){
          for(var a = 0; i< data.info.length; i++){
            state.step_9.push(data.info[a])
          }
          state.stages.step_9 = true
        }
      }
    },
    getters: {

   
      getActiveAction: (state) => (param) => {
 
 
         return state.actions.find(action => action.action_param === param)
       },
      
         },
    actions
}

export default reports