<template>
    <div class="mb-0 pb-0">
        <h5 class="mb-2">{{ input_info.title }}</h5>
        <div v-if="input_info.field_type === 'text-field'">
            <v-text-field
                dense
                :type="input_info.input_type"
                outlined
                :label="input_info.label"
                v-model="input_info.value"
            ></v-text-field>
        </div>
        <div v-if="input_info.field_type === 'text-area'">
            <v-textarea outlined dense :label="input_info.label" v-model="input_info.value"></v-textarea>
        </div>
        <div v-if="input_info.field_type === 'select'">
            <v-select
                outlined
                class="text-lowercase"
                :items="input_info.items"
                :label="input_info.label"
                dense
                v-model="input_info.value"
            ></v-select>
        </div>
        <div v-if="input_info.field_type === 'checkbox'">
            <span v-for="input_check in input_info.items" :key="input_check" class="py-0 my-0">
                <v-checkbox
                dense
                    v-model="input_info.value"
                    :label="input_check"
                    :value="input_check"
                ></v-checkbox>
            </span>
           
        </div>
        <div v-if="input_info.field_type === 'select-input'">
            <v-select
                outlined
                :items="input_info.items"
                :label="input_info.label"
                dense
                v-model="input_info.value"
            ></v-select>
            <v-text-field
                v-if="input_info.value === input_info.input_value_condition"
                :type="input_info.input_type"
                dense
                outlined
                :label="input_info.label_2"
                v-model="input_info.value_2"
            ></v-text-field>
        </div>

        <div v-if="input_info.field_type === 'document'">
            <ImageUpload :image_info="input_info"></ImageUpload>
        </div>
        <div v-if="input_info.field_type === 'documents'">
            <ImageUploadMultiple :image_info="input_info" :images_urls="input_info.images_urls"></ImageUploadMultiple>
        </div>
    </div>
</template>
<script>
import ImageUpload from '@/components/ImageUpload.vue'
import ImageUploadMultiple from '@/components/ImageUploadMultiple.vue'

export default {
    name: 'FormInput',
    components: {
        ImageUpload,
        ImageUploadMultiple
    },
    props: {
        input_info: Object
    }
}
</script>