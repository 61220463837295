<template>
  <v-container>
    <v-row class="mt-2">
      <v-col md="12">
        <v-stepper v-model="e6" vertical>
        
          <v-stepper-content step="0">
            <v-row>
              <v-col cols="12">
                <v-card elevation="0" class="text-center">
                  <v-card-text>
                    <h4>FOLLOW UP SURVEY</h4>
                <br />
                <p style=" text-align: justify;
                margin: 0 auto;
  text-justify: inter-word;">
                  <strong>congratulations! You accomplished another step of your grassroots growth journey.</strong>
                  <br />
                  <br />Take the time to fill out this survey & attach your follow-up material. The more information and feedback, the better you appear towards your supportive community in terms of credibility and impact: they can trust you, stay interested & may donate again. The same for collaborations: other initiatives, artists & networks can work together with you in the future – showing the action journey as a strong portfolio.
                  <br />
                  <br />On ardkon platform we can level up for the next steps: we share the accomplished goals, can tailor your existing action page accordingly and together we can come up with new supportive acts & connect you to possible organizers… or maybe you want to create a new action that focuses on another field you are involved in, a new project or collaboration!
                  <br />
                  <br />
Wherever the journey goes from here, we help you grow within the framework of these three pillars:
                  compliance, visibility & growth.
                </p>
                  </v-card-text>
                </v-card>
               
              </v-col>
            </v-row>
            <v-btn color="primary" block @click="e6 = e6 + 1 ">start report</v-btn>
          </v-stepper-content>
          <v-stepper-step :complete="e6 > 1" step="1">general information</v-stepper-step>

          <v-stepper-content step="1">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_1" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>


          </v-stepper-content>

          <v-stepper-step :complete="e6 > 2" step="2">funding plan & goals</v-stepper-step>

          <v-stepper-content step="2">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_2" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-step :complete="e6 > 3" step="3">supportive act</v-stepper-step>

          <v-stepper-content step="3">
            <p>Did your action benefit from a supportive act (music event, panel, pop-up store auction etc.) conducted by or in collaboration with other actors? If not, skip section.</p>
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_3" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-step :complete="e6 > 4" step="4">where to go from here:</v-stepper-step>
          <v-stepper-content step="4">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_4" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>
          <v-stepper-step :complete="e6 > 5" step="5">impact</v-stepper-step>
          <v-stepper-content step="5">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_5" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>
          <v-stepper-step step="6" :complete="e6 > 6">democratization & supporter participation:</v-stepper-step>
          <v-stepper-content step="6">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_6" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-step step="7" :complete="e6 > 7">comments and questions</v-stepper-step>
          <v-stepper-content step="7">
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_7" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>

          <v-stepper-step step="8" :complete="e6 > 8">submissions - transparency</v-stepper-step>
          <v-stepper-content step="8">
            <h4>transparency</h4>
            <h5>please attach</h5>
            <br>
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_8" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

             </v-stepper-content>

         <v-stepper-step step="9" :complete="e6 > 9">submissions - visibility </v-stepper-step>
          <v-stepper-content step="9">
         
            <h5>please attach</h5>
            <br>
            <v-row>
              <v-col cols="12" md="6" v-for="input in step_9" :key="input.id">
                <FormInput :input_info="input"></FormInput>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-btn tile color="secondary" block class="primary--text text-lowercase" @click="back">back</v-btn>

              </v-col>  <v-col cols="12" md="6">
                <v-btn tile block color="primary"  class=" text-lowercase" @click="save">save</v-btn>

              </v-col>
            </v-row>

          </v-stepper-content>
         
          <v-stepper-content step="10">
            <v-row>
              <v-col cols="12">
                <p  v-if="submitted === false">
                  <v-btn color="primary" block @click="submit">Submit Report</v-btn>
                  <small>{{message_submit}}</small>  
                </p>
     
                <p v-else>
                  <strong> Thank you for filling out this survey!</strong><br><br>
                We want you to know that your initiative is actively supporting the creation of a flourishing grassroots ecosystem on ardkon platform. This survey is meant for you to analyze your performance & sustain the trust of your supportive community, plus all of your input is enabling us to develop tools that are tailored for the needs of grassroots creators and founders.<br><br>
                ardkon believes in the transformative potential of grassroots – we strive to create an ecosystem of compliance, visibility & growth.
             
                </p>
                </v-col>
            </v-row>

          </v-stepper-content>
        </v-stepper>
       
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormInput from '@/components/FormInput.vue'
import store from '@/store'
  export default {
    name: 'Home',
    data(){
      return {
        e6: 0,
        param: this.$route.params.action_params,
        submitted: false,
        message_submit: '',
        m_step: 10
      }
    },
  
    components: {
      FormInput,
    },
   async  mounted(){
      await store.dispatch('reports/getActions')
    await store.dispatch('reports/getFundAllocation', { action_id: this.action.action_id })

    },
    methods:{
      submit(){
        if(this.stages.step_1 === false){
          this.message_submit = 'complete step 1'
          this.m_step = 1
        }
        else if(this.stages.step_2 === false){
          this.message_submit = 'complete step 2'
          this.m_step = 2


        }else if(this.stages.step_3 === false){
          this.message_submit = 'complete step 3'
          this.m_step = 3


        }else if(this.stages.step_4 === false){
          this.message_submit = 'complete step 4'
          this.m_step = 4


        }else if(this.stages.step_5 === false){
          this.message_submit = 'complete step 5'
          this.m_step = 5


        }else if(this.stages.step_6 === false){
          this.message_submit = 'complete step 6'
          this.m_step = 6


        }else if(this.stages.step_7 === false){
          this.message_submit = 'complete step 7'

          this.m_step = 7

        }else if(this.stages.step_8 === false){
          this.message_submit = 'complete step 8'
          this.m_step = 8


        }else if(this.stages.step_9 === false){
          this.message_submit = 'complete step 9'
          this.m_step = 9


        } else {
          this.message_submit = 'submitted'
          this.submitted = true
          store.dispatch('reports/submit' ,{name: this.param})

        }

      },
      save(){
        var form = {}

        if(this.e6 === 1){
          this.e6 = this.e6 +1

           form = {
          e6: 1,
          info: this.step_1
        }
        store.dispatch('reports/saveData',form )
        } 
        else if(this.e6 === 2){
          this.e6 = this.e6 +1

           form = {
          e6: 2,
          info: this.step_2
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 3){
          this.e6 = this.e6 +1

           form = {
          e6: 3,
          info: this.step_3
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 4){
          this.e6 = this.e6 +1

           form = {
          e6: 4,
          info: this.step_4
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 5){
          this.e6 = this.e6 +1

           form = {
          e6: 5,
          info: this.step_5
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 6){
          this.e6 = this.e6 +1

           form = {
          e6: 6,
          info: this.step_6
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 7){
          this.e6 = this.e6 +1

           form = {
          e6: 7,
          info: this.step_7
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 8){
          this.e6 = this.e6 +1

           form = {
          e6: 8,
          info: this.step_8
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 9){
          this.e6 = this.e6 +1

           form = {
          e6: 9,
          info: this.step_9
        }
        store.dispatch('reports/saveData',form )
        }
        
      }, 
      skip(){
        this.e6 = this.e6 +1
      },
      back(){
        this.e6 = this.e6  - 1
      }
    },
    computed:{
      action() {
      return store.getters['reports/getActiveAction'](this.param)

    },
      step_1(){
        return store.state.reports.step_1
      },
      step_2(){
        return store.state.reports.step_2
      },
      step_3(){
        return store.state.reports.step_3
      },
      step_4(){
        return store.state.reports.step_4
      },
      step_5(){
        return store.state.reports.step_5
      },
      step_6(){
        return store.state.reports.step_6
      },
      step_7(){
        return store.state.reports.step_7
      },
      step_8(){
        return store.state.reports.step_8
      },
      step_9(){
        return store.state.reports.step_9
      },
      stages(){
        return store.state.reports.stages
      },
    }
  }
</script>
