<template>
  <v-container>
    <v-row class="mt-2">
      <v-col md="12">
      <v-card>
        <v-card-text>
          This Platform is conducted for reporting all the actions on ardkon.com
        </v-card-text></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '@/store'
  export default {
    name: 'Home',
    data(){
      return {
        e6: 10,

      }
    },
  
    components: {
    },
    methods:{
      submit(){
        store.dispatch('reports/submit' )

      },
      save(){
        console.log(this.step_1)
        var form = {}

        if(this.e6 === 1){
          this.e6 = this.e6 +1

           form = {
          e6: 1,
          data: this.step_1
        }
        store.dispatch('reports/saveData',form )
        } 
        else if(this.e6 === 2){
          this.e6 = this.e6 +1

           form = {
          e6: 2,
          data: this.step_2
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 3){
          this.e6 = this.e6 +1

           form = {
          e6: 3,
          data: this.step_3
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 4){
          this.e6 = this.e6 +1

           form = {
          e6: 4,
          data: this.step_4
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 5){
          this.e6 = this.e6 +1

           form = {
          e6: 5,
          data: this.step_5
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 6){
          this.e6 = this.e6 +1

           form = {
          e6: 6,
          data: this.step_6
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 7){
          this.e6 = this.e6 +1

           form = {
          e6: 7,
          data: this.step_7
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 8){
          this.e6 = this.e6 +1

           form = {
          e6: 9,
          data: this.step_8
        }
        store.dispatch('reports/saveData',form )
        }
        else if(this.e6 === 9){
          this.e6 = this.e6 +1

           form = {
          e6: 9,
          data: this.step_9
        }
        store.dispatch('reports/saveData',form )
        }
        
      }, 
      skip(){
        this.e6 = this.e6 +1
      },
      back(){
        this.e6 = this.e6  - 1
      }
    },
    computed:{
      step_1(){
        return store.state.reports.step_1
      },
      step_2(){
        return store.state.reports.step_2
      },
      step_3(){
        return store.state.reports.step_3
      },
      step_4(){
        return store.state.reports.step_4
      },
      step_5(){
        return store.state.reports.step_5
      },
      step_6(){
        return store.state.reports.step_6
      },
      step_7(){
        return store.state.reports.step_7
      },
      step_8(){
        return store.state.reports.step_8
      },
      step_9(){
        return store.state.reports.step_9
      },
    }
  }
</script>
